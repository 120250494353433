import type { FC, ReactNode } from 'react';
import React from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { confluenceSessionStorageInstance as sessionStorage } from '@confluence/storage-manager';

import { CollapsibleSection, ControlledCollapsibleSection } from './CollapsibleSection';
import { updateExpandedState } from './updateExpandedState';
import { useSpacePersistentExpansion } from './useSpacePersistentExpansion';

const getInitialExpandedState = (
	sectionName: string,
	sessionStorageKey: string,
	defaultExpanded?: boolean,
) => {
	if (sessionStorage.doesContain(sessionStorageKey)) {
		const sideNavSectionStates = sessionStorage.getItem(sessionStorageKey);
		return sideNavSectionStates[sectionName] ?? defaultExpanded;
	}
	return defaultExpanded ?? true;
};

type PersistentCollapsibleSectionProps = {
	renderAfterIconButton: (
		setHeaderExpandedState: ((newIsOpen: boolean) => void) | undefined,
	) => ReactNode;
	children: ReactNode;
	defaultIsExpanded: boolean;
	headerTitle: string;
	onClick?: (isOpen: boolean) => void;
	sectionName: string;
	sessionStorageKey: string;
	tooltipContent?: string;
};

type SpacePersistentCollapsibleSectionProps = Omit<
	PersistentCollapsibleSectionProps,
	'sessionStorageKey' | 'defaultIsExpanded'
> & {
	spaceKey: string;
	collapsedByDefault?: boolean;
};

export const PersistentCollapsibleSection: FC<PersistentCollapsibleSectionProps> = ({
	renderAfterIconButton,
	children,
	defaultIsExpanded,
	headerTitle,
	onClick = () => {},
	sectionName,
	sessionStorageKey,
	tooltipContent,
}) => {
	return (
		<CollapsibleSection
			renderAfterIconButton={renderAfterIconButton}
			headerTitle={headerTitle}
			initialExpandedState={getInitialExpandedState(
				sectionName,
				sessionStorageKey,
				defaultIsExpanded,
			)}
			onClick={(isOpen) => {
				onClick(isOpen);
				updateExpandedState({
					isOpen,
					sectionKey: sectionName,
					sessionStorageKey,
				});
			}}
			tooltipContent={tooltipContent}
		>
			{children}
		</CollapsibleSection>
	);
};

export const SpacePersistentCollapsibleSection: FC<SpacePersistentCollapsibleSectionProps> = ({
	renderAfterIconButton,
	children,
	headerTitle,
	sectionName,
	spaceKey,
	tooltipContent,
	collapsedByDefault,
}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { isExpanded, onExpansionToggle } = useSpacePersistentExpansion(
		spaceKey,
		sectionName,
		collapsedByDefault,
	);

	const onClick = (isOpen: boolean) => {
		onExpansionToggle(!isOpen);
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: !isOpen ? 'opened' : 'closed',
				actionSubject: 'collapsibleSectionHeader',
				source: 'sideNavigation',
				attributes: {
					section: sectionName,
				},
			},
		}).fire();
	};

	return (
		<ControlledCollapsibleSection
			renderAfterIconButton={renderAfterIconButton}
			headerTitle={headerTitle}
			isOpen={isExpanded}
			setIsOpen={onExpansionToggle}
			onClick={onClick}
			tooltipContent={tooltipContent}
		>
			{children}
		</ControlledCollapsibleSection>
	);
};
